<template>
  <div>
    <NavBar
      style="max-height: 50px; margin-bottom: 10px"
      :username="currentUserName"
      :pageName="pageName"
      class="topNavBar"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93.5vh !important">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px" :pageName="pageName" class="sideBar"></SideBar>
          </v-col>
          <v-col cols="10" align="left">
            <span class="pageTitleClass"
              style="text-align: left;
                margin-left: 65px;
                margin-top: 80px;
                font-weight: 800;
                text-transform: uppercase;
                letter-spacing: 1px;">
              My Profile
            </span>
            <div class="pa-4 mobileProfileCard">
              <v-card class="pa-8">
                <div style="display: flex; justify-content: center;">
                  <div>
                    <V-avatar size="100">
                      <img v-bind:src="doctor_profile_picture" alt="Image" />
                    </V-avatar>
                  </div>
                </div>
                <div align="center" style="font-weight: 600; font-size: 12pt">
                  For any data changes, please contact CE team   <span>(HotLine Number): 5588 and +959779995588</span>
                </div>
              </v-card>
            </div>
            <div v-if="!isLoadingData">
              <v-card
                style="padding:10px; margin:20px 50px; height: 80vh;  overflow:scroll; overflow-x: hidden;"
                class="profilePageCard" >
                <div style="margin: 20px 30px" class="doctorDataClass">
                  <div class="desktopProfileImg">
                    <div style="margin-bottom: 20px; margin-left: auto !important; margin-right: auto !important;" class="d-flex flex-column justify-center align-center">
                      <V-avatar size="100">
                        <img v-bind:src="doctor_profile_picture" alt="Image" />
                      </V-avatar>
                    </div>
                  </div>
                  <div
                    v-if="selected_doctor"
                    style="display: flex; flex-direction: column; width: 60%;"
                    align="center"
                    class="doctorData justify-center align-center">
                    <div align="center" style="font-weight: 600; font-size: 12pt" class="desktopProfileImg">
                      For any data changes, please contact CE team   <span>(HotLine Number): 5588 and +959779995588</span>
                    </div>
                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Full Name:</span>
                      </div>
                      <div class="docvalue" align="right">
                        <span> {{ doctor_name }} </span>
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Gender:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ doctor_gender }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Date Of Birth:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ doctorDateOFBirth }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>


                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Age:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ age }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" aling="center">
                      <div class="doctitle" align="left">
                        <span> SAMA ID:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ doctor_id }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Email:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ doctor_email }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Phone Number:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ phone_number }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Alternate Phone Number:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ altPhoneNumber }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Residential State:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ residentialState }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Affiliated Hospital:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ affiliatedHospital }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Speciality:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ selected_specialization }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Specialized Subject:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ specializedSubject }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Relevent Medical Degree:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ qualifyingDgree }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span>Academic Title:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ acadamicTitle }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span class="doctitle"> KBZ Bank Account:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ account_number }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 190%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span class="doctitle"> Suggested Medicine To Keep:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ suggestedMedicineToStock }}
                      </div>
                    </div>
                    <div style="margin: 12px 0px 0px 0px">
                      <h5 style="text-align:left">Committed Teleconsultation Information</h5>
                    </div>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Service Fee:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ service_fee }} MMK
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Consultation Minutes:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ consultationMinutes }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Break Minutes before Teleconsultation:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ breakTimeMinutes }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>

                    <div class="headline" align="center">
                      <div class="doctitle" align="left">
                        <span> Committed Schedule:</span>
                      </div>
                      <div class="docvalue" align="right">
                        {{ commitedShedule }}
                      </div>
                    </div>
                    <v-divider
                      style="border-color: #c9e5ff; width: 100%"
                    ></v-divider>
                    <div style="display: none">
                      <v-btn
                        style="width: 50%"
                        color="primary"
                        @click="editDoctors"
                        >Apply Changes</v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else align="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
    <bottomNavBar :pageName = 'pageName' class="bottomNavBar"></bottomNavBar>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      selected_doctor: "",
      doctor_name: "",
      doctor_name_mm:"",
      age:"",
      doctorDateOFBirth:"",
      altPhoneNumber:"",
      residentialState:"",
      affiliatedHospital:"",
      specializedSubject:"",
      qualifyingDgree:"",
      acadamicTitle:"",
      suggestedMedicineToStock:"",
      consultationMinutes:"",
      breakTimeMinutes:"",
      commitedShedule:"",
      doctor_email: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phone_number: "",
      pageName: "DoctorProfile",
      specialist_array: [],
      selected_specialization: "",
      short_description: "",
      doctor_id: "",
      gender: ["Male", "Female", "Other"],
      doctor_gender: "",
      service_year: "",
      account_number: 0,
      available_status_list: ["UNAVAILABLE", "AVAILABLE"],
      availability_status: "",
      service_fee: "",
      mode_of_settlement_list: ["Monthly", "Bi-weekly"],
      selected_payment: "",
      isLoadingData: true,
      doctor_profile_picture: "",
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("doctorToken");
    document.title = "Doctor Profile";
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var data = {
        token: this.currentUser,
        typeOfUser: "DOCTOR",
      };
      axios_auth_instance_doctor
        .post("/doctorProfilePage", data)
        .then((successResponse) => {
          this.currentUserName = successResponse.data.doctor_data.doctor_name;
          this.user_id = successResponse.data.doctor_data._id;
          this.selected_doctor = this.user_id;
          this.doctor_name = successResponse.data.doctor_data.doctor_name;
          this.doctor_gender = successResponse.data.doctor_data.gender;
          this.service_year = successResponse.data.doctor_data.service_year;
          this.service_fee = successResponse.data.doctor_data.service_fee;
          this.account_number = successResponse.data.doctor_data.kbz_bank_account_number;
          this.doctor_id = successResponse.data.doctor_data.doctor_id;
          this.doctor_email = successResponse.data.doctor_data.doctor_email;
          this.phone_number = successResponse.data.doctor_data.doctor_phonenumber;
          this.short_description = successResponse.data.doctor_data.description;
          this.selected_payment = successResponse.data.doctor_data.mode_of_settlement;
          this.doctorDateOFBirth = successResponse.data.doctor_data.date_of_birth
          this.age = this.getAge(this.doctorDateOFBirth)
          this.altPhoneNumber =successResponse.data.doctor_data.doctor_alt_phonenumber
          this.residentialState = successResponse.data.doctor_data.doctor_res_state
          this.affiliatedHospital = successResponse.data.doctor_data.affiliated_hospital
          this.specializedSubject = successResponse.data.doctor_data.spec_subjects
          this.qualifyingDgree = successResponse.data.doctor_data.description
          this.acadamicTitle = successResponse.data.doctor_data.doctor_title
          this.suggestedMedicineToStock = successResponse.data.doctor_data.suggested_default_med
          this.consultationMinutes = successResponse.data.doctor_data.meeting_minutes
          this.breakTimeMinutes = successResponse.data.doctor_data.break_minutes
          this.commitedShedule = successResponse.data.doctor_data.committed_schedule
          this.selected_specialization = successResponse.data.specialization_name;
          if (successResponse.data.doctor_data.doctor_profile_picture == "" ||!successResponse.data.doctor_data.doctor_profile_picture) {
            if (successResponse.data.doctor_data.gender == "Female") {
              this.doctor_profile_picture =
                "https://d3vsnl8idgwrlw.cloudfront.net/female.svg";
            } else {
              this.doctor_profile_picture =
                "https://d3vsnl8idgwrlw.cloudfront.net/male.svg";
            }
          } else {
            this.doctor_profile_picture =
              successResponse.data.doctor_data.doctor_profile_picture;
          }

          this.specialist_array = successResponse.data.specialization_data;
          this.isLoadingData = false;
        })
        .catch((errorResponse) => {
          handleError(errorResponse, this.$router, 'doctorProfilePage', 'DoctorLogin', 'doctorToken');
        });
    }
  },
  components: {
    NavBar,
    SideBar,
    bottomNavBar: () => import('../../../components/docBottomNavBar.vue')
  },
  methods: {
    redirectToViewAllDoctors() {
      this.$router.push({
        name: "ViewAllDoctors",
      });
    },
    editDoctors() {
      var doctor_edit_body = {
        doctorId: this.selected_doctor,
        doctorName: this.doctor_name,
        specialist: [this.selected_specialization],
        description: this.short_description,
        doctorPhonenumber: this.phone_number,
        doctorEmail: this.doctor_email,
        status: "ACTIVE", //ask guru and vinuthna before deleting this field
        updatedBy: this.selected_doctor,
        availableStatus: "AVAILABLE", //ask guru and vinuthna before deleting this field
        kbzBankAccountNumber: this.account_number,
        serviceYear: this.service_year,
        serviceFee: this.service_fee,
        modeOfPayment: this.selected_payment,
        token: this.currentUser,
        typeOfUser: "DOCTOR",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/updateDoctorDetails",
          doctor_edit_body
        )
        .then((editDoctorsResponse) => {
          alert("Doctor edited");

          this.$router.push({
            name: "DoctorHome",
          });
        })
        .catch((editDoctorsError) => {
          if (editDoctorsError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "DoctorLogin",
            });
          } else if (editDoctorsError.response.status == 403) {
            window.alert("Please provide all the required fields");
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
    FillTable() {
      var doctor_body = {
        doctorId: this.selected_doctor,
        token: this.currentUser,
        typeOfUser: "DOCTOR",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getDoctorFromId", doctor_body)
        .then((FillTableResponse) => {
          var response_data = FillTableResponse.data.data;
          this.doctor_name = response_data.doctor_name;
          this.doctor_gender = response_data.gender;
          this.selected_specialization = response_data.specialist[0].name;
          this.service_year = response_data.service_year;
          this.service_fee = response_data.service_fee;
          this.account_number = response_data.kbz_bank_account_number;
          this.doctor_id = response_data.doctor_id;
          this.doctor_email = response_data.doctor_email;
          this.phone_number = response_data.doctor_phonenumber;
          this.service_year = response_data.service_year;
          this.short_description = response_data.description;
          this.selected_payment = response_data.mode_of_settlement;
          if (
            response_data.doctor_profile_picture == "" ||
            !response_data.doctor_profile_picture
          ) {
            if (response_data.gender == "Female") {
              this.doctor_profile_picture =
                "https://d3vsnl8idgwrlw.cloudfront.net/female.svg";
            } else {
              this.doctor_profile_picture =
                "https://d3vsnl8idgwrlw.cloudfront.net/male.svg";
            }
          } else {
            this.doctor_profile_picture = response_data.doctor_profile_picture;
          }
          var getAccessData = {
            token: this.currentUser,
            typeOfUser: "DOCTOR",
          };
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/readAllSpecializations",
              getAccessData
            )
            .then((readSpecResponse) => {
              this.specialist_array = readSpecResponse.data.data;
              this.isLoadingData = false;
            })
            .catch((readSpecError) => {
              window.alert(readSpecError);
            });
        })
        .catch((FillTableError) => {
          if (FillTableError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "DoctorLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
};
</script>

<style scoped>
.headline {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
  width: 100%;
}
td,
th {
  padding: 10px;
}
.doctitle {
  font-weight: normal;
  font-size: 14px !important;
  color: #4f4f4f;
  width: 50%;
}
.docvalue {
  width: 50%;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}
.bottomNavBar {
  display: none;
}
.mobileProfileCard{
    display: none !important;
  }
.desktopProfileImg{
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.doctorData{
  width: 60% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.doctorDataClass{
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 320px) and (max-width: 1024px){
  .bottomNavBar {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0px;
  }
  .topNavBar,.sideBar {
    display: none !important;
  }
  .col-2, .col-10{
    flex: unset !important;
    max-width: unset !important;
  }
  .col-2 {
    display: none !important;
  }
  .pageTitleClass{
    margin-left: 16px !important;
  }
  .mobileProfileCard{
    display: block !important;
  }
  .profilePageCard{
    margin:unset !important;
    height: 49vh !important;
  }
  .desktopProfileImg{
  display: none !important;
}
.doctorDataClass{
  margin: unset !important;
}
.doctorData{
  width: 100% !important;
}
}
</style>